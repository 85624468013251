import React, { Suspense, lazy, useEffect, useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import axios from "axios";

// pages here
import Index from "./pages/index";
import { ResetConfirm, ResetRequest } from "./pages/auth/reset";
import { RegisterForm, RegisterVerify } from "./pages/auth/register";

const Onboarding = lazy(() => import("./pages/onboarding"));
const ErrorPage = lazy(() => import("./pages/error"));
const DashboardPage = lazy(() => import("./pages/dashboard/index"));
const Test = lazy(() => import("./pages/packs/test/test"));
const TestV2 = lazy(() => import("./pages/packs/v2-Test-Analysis/test"));
const LeaderBoard = lazy(() => import("./pages/packs/test/leaderboard"));
const Version = lazy(() => import("./pages/version"));

const Profile = lazy(() => import("./pages/account/profile"));
const Settings = lazy(() => import("./pages/account/settings"));
const Solutions = lazy(() => import("./pages/packs/test/solutions"));
const TestSeries = lazy(() => import("./pages/test-series"));

// Auth Routes
const Auth = lazy(() => import("./pages/auth"));
const AuthAdmin = lazy(() => import("./pages/auth/admin"));
const Logout = lazy(() => import("./pages/auth/logout"));

// Test Routes
const TestUI = lazy(() => import("./pages/tests"));
const Inst = lazy(() => import("./pages/tests/instructions"));
const Sub = lazy(() => import("./pages/tests/submission"));
const Login = lazy(() => import("./pages/auth/login"));
const Maintenance = lazy(() => import("./pages/maintenance"));
const GraphTest = lazy(() => import("./pages/graphs"));

// V2 Design Routes
const Home = lazy(() => import("./pages/v2/home"));
const Dash = lazy(() => import("./pages/v2/dash"));
const AYJR = lazy(() => import("./pages/v2/ayjr"));
const OverallAnalysis = lazy(() => import("./pages/v2/overallPackAnalysis"));
const SubjectAnalysis = lazy(() => import("./pages/v2/subjectWiseAnalysis"));
const ChapterAnalysis = lazy(() => import("./pages/v2/chapterWiseAnalysis"));

const UP = JSON.parse(localStorage.getItem("userProfile"));
const bg = UP ? (UP.settings.isDarkTheme ? "#12263F" : "white") : "white";

const Router = () => {
  const [isLoginAllowed, setIsLoginAllowed] = useState(false);
  const [isV2AnalysisAvailable, setIsV2AnalysisAvailable] = useState(false);

  useEffect(() => {
    const checkLogin = async () => {
      const userPacks = await axios.get(`/api/auth/loginallowed`);
      const { isLoginAllowed, redirectUrl, isV2Analysis } = userPacks.data;
      setIsLoginAllowed(isLoginAllowed);
      setIsV2AnalysisAvailable(isV2Analysis);
      if (!isLoginAllowed) {
        window.location.href = redirectUrl;
      }
    };
    checkLogin();
  }, []);

  return process.env.REACT_APP_MAINTENANCE_MODE ? (
    <Maintenance />
  ) : (
    <BrowserRouter>
      <Suspense fallback={<div style={{ height: "100vh", backgroundColor: bg }}></div>}>
        {" "}
        {isLoginAllowed ? (
          <Switch>
            <Route path="/" component={Index} exact />
            <Route path="/auth" component={Auth} exact />
            <Route path="/auth/admin" component={AuthAdmin} exact />
            <Route path="/auth/register" component={RegisterForm} exact />
            <Route path="/auth/verifyEmail" component={RegisterVerify} exact />
            <Route path="/auth/login" component={Login} exact />
            <Route path="/auth/reset/request" component={ResetRequest} exact />
            <Route path="/auth/reset/confirm" component={ResetConfirm} exact />
            <Route path="/account" component={Profile} exact />
            <Route path="/logout" component={Logout} exact />
            <Route path="/onboarding" component={Onboarding} exact />
            <Route path="/dashboard" component={DashboardPage} exact />
            <Route path="/test-series" component={TestSeries} exact />

            <Route path="/account/profile" component={Profile} />
            <Route path="/account/settings" component={Settings} />

            {/* V2 Design Routes */}
            <Route path="/v2/home" component={Home} />
            <Route path="/v2/dash" component={Dash} />
            <Route path="/v2/ayjr" component={AYJR} />
            <Route path="/v2/overall-pack-analysis" component={OverallAnalysis} />
            <Route path="/v2/subject-wise-analysis" component={SubjectAnalysis} />
            <Route path="/v2/chapter-wise-analysis" component={ChapterAnalysis} />

            {/* Test Routes */}
            <Route path="/tests/:pack/:test/submission" component={Sub} exact />
            <Route path="/tests/:pack/:test/instructions" component={Inst} exact />
            <Route path="/tests/:pack/:test" component={TestUI} exact />

            <Route path="/:packId/:testId/solutions" component={Solutions} />
            <Route path="/:packId/:testId/leaderboard" component={LeaderBoard} />
            <Route path="/:packId/:testId" component={isV2AnalysisAvailable ? TestV2 : Test} />
            <Route path="/version" component={Version} exact />
            <Route path="/graphs" component={GraphTest} exact />

            {/* Error Routes */}
            <Route path="/no-connection">
              <ErrorPage type={504} />
            </Route>
            <Route path="*">
              <ErrorPage type={404} />
            </Route>
          </Switch>
        ) : null}
      </Suspense>
    </BrowserRouter>
  );
};

export default Router;
